/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC } from 'react'
import {
  CollectionTemplateType,
  FluidImageObjectType,
  ProductItemComponentType,
} from '~/@types/models'
import Collection from './index'

type QueryResult = {
  collectionHero: FluidImageObjectType
  pageData: {
    html: string
    frontmatter: {
      pairsWellWith: ProductItemComponentType[]
      categoriesProducts: ProductItemComponentType[]
      featuredProducts: ProductItemComponentType[]
      image: FluidImageObjectType
    }
  }
}

type Props = CollectionTemplateType & {
  data: QueryResult
}

const CollectionArt: FC<Props> = props => <Collection {...props} />

export default CollectionArt
